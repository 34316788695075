import { createAsyncThunk } from '@reduxjs/toolkit';

export const createFetchAsync = (module, name, payloadCreator) => {
  const asyncThunk = createAsyncThunk(`${module}/${name}`, payloadCreator);
  const initialState = { isLoading: false, payload: null, errors: null };

  return {
    asyncThunk,
    initialState,
    extraReducers: {
      [asyncThunk.pending]: (state) => {
        state[name] = { isLoading: true, payload: null, errors: null };
      },
      [asyncThunk.fulfilled]: (state, { payload }) => {
        state[name] = {
          isLoading: false,
          payload: payload,
          errors: null,
        };
      },
      [asyncThunk.rejected]: (state, { payload }) => {
        state[name] = {
          isLoading: false,
          payload: null,
          errors: payload,
        };
      },
    },
  };
};
