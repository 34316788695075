import { createSlice } from '@reduxjs/toolkit';
import { createFetchAsync } from '../../util/FetchSliceUtils';
import { UserApi } from '../../http/requests/UserApi';
import { Modal } from 'antd';
const api = new UserApi();

const fetchAllUserAsync = createFetchAsync(
  'User',
  'all',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.all({
        ...data,
        embed: 'createdBy,updatedBy,cemetery',
      });
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
const fetchCreateUserAsync = createFetchAsync(
  'User',
  'create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.create(data);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

const fetchUpdateUserAsync = createFetchAsync(
  'User',
  'update',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await api.update(id, data);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

const fetchDeleteUserAsync = createFetchAsync(
  'User',
  'delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.delete(id);
      return response.data;
    } catch (e) {
      if (e.response.status === 403) {
        Modal.error({
          title: 'Хатолик',
          content: 'Сиз ушбу ресурсни учира олмайсиз!',
        });
      }
      return rejectWithValue(e.response.data);
    }
  }
);

export const fetchAllUser = fetchAllUserAsync.asyncThunk;
export const fetchCreateUser = fetchCreateUserAsync.asyncThunk;
export const fetchUpdateUser = fetchUpdateUserAsync.asyncThunk;
export const fetchDeleteUser = fetchDeleteUserAsync.asyncThunk;

const userSlice = createSlice({
  name: 'User',
  initialState: {
    all: fetchAllUserAsync.initialState,
    create: fetchCreateUserAsync.initialState,
    update: fetchUpdateUserAsync.initialState,
    delete: fetchDeleteUserAsync.initialState,
  },
  extraReducers: {
    ...fetchAllUserAsync.extraReducers,
    ...fetchCreateUserAsync.extraReducers,
    ...fetchUpdateUserAsync.extraReducers,
    ...fetchDeleteUserAsync.extraReducers,
  },
});

export default userSlice.reducer;
