import React from 'react';
import { Button, Skeleton, Space } from 'antd';
import _ from 'lodash';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

export const TableViewRowLoading = ({ columns, displayColumns }) => {
  return (
    <tr className="ant-table-row">
      {_(columns)
        .filter((column) => displayColumns.indexOf(column.dataIndex) !== -1)
        .value()
        .map((column, idx) => (
          <td className="ant-table-cell" key={idx}>
            <Skeleton.Input style={{ width: 50 }} active size="small" />
          </td>
        ))}
      <td className="ant-table-cell">
        <Space>
          <Skeleton.Button active size="small" shape="circle" />
          <Skeleton.Button active size="small" shape="circle" />
        </Space>
      </td>
    </tr>
  );
};

export const TableViewRow = ({
  displayColumns,
  hit,
  columns,
  onUpdate,
  onDelete,
  user,
}) => {
  return (
    <tr className="ant-table-row">
      {_(columns)
        .filter((column) => displayColumns.indexOf(column.dataIndex) !== -1)
        .value()
        .map((column, idx) => (
          <td className="ant-table-cell" key={idx}>
            {_.isFunction(column.render)
              ? column.render(hit)
              : _.get(hit, column.dataIndex)}
          </td>
        ))}

      <td className="ant-table-cell">
        {!user.isModerator() && (
          <>
            <Button
              className="gx-mb-0"
              icon={<EditOutlined />}
              size="small"
              onClick={() => onUpdate(hit.id)}
            />
            <Button
              className="gx-mb-0"
              icon={<DeleteOutlined />}
              size="small"
              danger
              onClick={() => onDelete(hit.id)}
            />
          </>
        )}
      </td>
    </tr>
  );
};
