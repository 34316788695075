import React from 'react';
import { Typography } from 'antd';
import clsx from 'clsx';

export const Heading = ({ children, center, ...props }) => {
  return (
    <Typography.Title
      {...props}
      className={clsx({ 'gx-text-center': !!center })}>
      {children}
    </Typography.Title>
  );
};
