import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { get } from 'lodash';

export const FieldSelect = ({
  name,
  prefix,
  rules,
  placeholder,
  errors,
  InputProps,
  options,
  onChange,
  ...FormItemProps
}) => {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const errorMessage = get(errors, ['message', name], '');
    setErrorMessage(errorMessage);
  }, [errors, name]);

  const itemProps = () => {
    let inputProps = {
      rules,
      name,
      colon: false,
      ...FormItemProps,
    };
    if (errorMessage) {
      return {
        ...inputProps,
        validateStatus: 'error',
        help: errorMessage,
      };
    }
    return inputProps;
  };

  const handleChange = () => {
    setErrorMessage('');
  };

  return (
    <Form.Item {...itemProps()}>
      <Select
        showSearch
        filterOption={(input, option) => {
          return (
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        placeholder={placeholder}
        onChange={onChange ? onChange : handleChange}
        allowClear>
        {options.map((option) => (
          <Select.Option value={option.id} key={option.id}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
