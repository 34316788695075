export const Districts = {
  BEKTEMIR: 'Бектемир тумани',
  CHILANZAR: 'Чилонзор тумани',
  MIRABAD: 'Миробод тумани',
  MIRZO_ULUGBEK: 'Мирзо Улуғбек тумани',
  OLMAZOR: 'Олмазор тумани',
  SERGELI: 'Сергели тумани',
  SHAYKHONTOHUR: 'Шайхонтохур  тумани',
  UCHTEPA: 'Учтепа тумани',
  YAKKASARAYOR: 'Яккасарой тумани',
  YASHNOBOD: 'Яшнобод тумани',
  YUNUSABAD: 'Юнусобод тумани',
  YANGI_XAYOT: 'Янги хаёт тумани',
};

export const Roles = {
  ADMIN: 'Администратор',
  MODERATOR: 'Модератор',
  DIRECTOR: 'Мудир',
  OPERATOR: 'Назоратчи',
};
