import { createSlice } from '@reduxjs/toolkit';
import {
  LAYOUT_TYPE_FULL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_COLOR,
  THEME_TYPE_SEMI_DARK,
} from '../constants/ThemeSetting';

const initialState = {
  pageTitle: '',
  navCollapsed: true,
  navStyle: NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_SEMI_DARK,
  themeColor: THEME_COLOR,

  pathname: '/',
  width: window.innerWidth,
  isDirectionRTL: false,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
};

const Settings = createSlice({
  name: 'Settings',
  initialState,
  reducers: {
    updatePageTitle: (state, { payload }) => {
      state.pageTitle = payload;
    },
    toggleCollapsedSideNav: (state, action) => {
      state.navCollapsed = action.payload;
    },
    updateWindowWidth: (state, action) => {
      state.width = action.payload;
    },
    setThemeType: (state, action) => {
      state.themeType = action.payload;
    },
    setThemeColor: (state, action) => {
      state.themeColor = action.payload;
    },

    onNavStyleChange: (state, action) => {
      state.navStyle = action.payload;
    },
    onLayoutTypeChange: (state, action) => {
      state.layoutType = action.payload;
    },
    switchLanguage: (state, action) => {
      state.locale = action.payload;
    },
  },
});

export const {
  updatePageTitle,
  updateWindowWidth,
  setThemeType,
  setThemeColor,
  onNavStyleChange,
  onLayoutTypeChange,
  switchLanguage,
  toggleCollapsedSideNav,
} = Settings.actions;

export default Settings.reducer;
