import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../http/axios';
import { AuthApi } from '../http/requests/AuthApi';
import { SIGNIN } from '../constants/RouteConstants';
const api = new AuthApi();

export const fetchLogin = createAsyncThunk(
  'Auth/fetchLogin',
  async (credentials, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.requestToken(credentials);

      const { data } = response;
      if (data.token) {
        dispatch(setBearer(data.token));
        setTimeout(() => {
          dispatch(fetchUserInfo());
        }, 100);

        return data;
      }

      return rejectWithValue({
        message: { password: 'Логин ёки парол нотугри' },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserInfo = createAsyncThunk(
  'Auth/FetchUserInfo',
  async (payload, { dispatch }) => {
    try {
      const accessToken = localStorage.getItem('token');
      if (accessToken) {
        dispatch(setBearer(accessToken));
      }

      return await api.userInfo();
    } catch (error) {
      console.error(error);
      dispatch(logout());
    }
  }
);

const initialState = {
  user: null,
  fetchLogin: {
    isLoading: false,
    payload: null,
    errors: null,
  },
};

const AuthSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    setBearer: (state, { payload }) => {
      localStorage.setItem('token', payload);
      axios.defaults.headers.common['Authorization'] = `Bearer ${payload}`;
    },
    logout: (state) => {
      state.user = {
        isGuest: true,
      };
      localStorage.removeItem('token');
      axios.defaults.headers.common['Authorization'] = '';
      window.location.href = SIGNIN;
    },
    login: (state, { payload }) => {
      state.user = {
        isGuest: false,
        ...payload,
      };
    },
    setInitUrl: (state, action) => {
      state.initURL = action.payload;
    },
  },

  extraReducers: {
    [fetchLogin.pending]: (state) => {
      state.fetchLogin.isLoading = true;
    },
    [fetchLogin.fulfilled]: (state, { payload }) => {
      state.fetchLogin = {
        isLoading: false,
        payload: payload,
        errors: null,
      };
    },
    [fetchLogin.rejected]: (state, { payload }) => {
      state.fetchLogin = {
        isLoading: false,
        payload: null,
        errors: payload,
      };
    },
    [fetchUserInfo.fulfilled]: (state, { payload }) => {
      state.user = payload;
    },
  },
});

export const { setInitUrl, logout, login, setBearer } = AuthSlice.actions;
export default AuthSlice.reducer;
