import { BaseDTO } from './BaseDTO';

export class UserDTO extends BaseDTO {
  firstName;
  lastName;
  role;

  isAdmin() {
    return ['ADMIN', 'MODERATOR'].includes(this.role);
  }

  isModerator() {
    return this.role === 'MODERATOR';
  }

  isNotAdmin() {
    return this.role !== 'ADMIN';
  }

  isDirector() {
    return this.role === 'DIRECTOR';
  }

  isOperator() {
    return this.role === 'OPERATOR';
  }
}
