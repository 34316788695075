import React from 'react';
import {
  compose,
  withHandlers,
  withProps,
  lifecycle,
  withState,
} from 'recompose';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { get, isFunction } from 'lodash';
import { history } from '../store/createStore';

export default (api, resource, dialog, ...fns) => {
  const mapStateToProps = (state) => {
    const resourceState = state[resource.storeName] || {};

    return {
      all: {
        isLoading: get(resourceState.all, 'isLoading', false),
        hits: get(resourceState.all, 'payload.hits', []),
        pagination: get(resourceState.all, 'payload.pagination', {}),
      },
      create: resourceState.create,
      update: resourceState.update,
      delete: resourceState.delete,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      getAll: (filters) => dispatch(resource.actions.all(filters)),
      createOne: (payload) => dispatch(resource.actions.create(payload)),
      updateOne: (id) => (data) =>
        dispatch(resource.actions.update({ id, data })),
      deleteOne: (id) => dispatch(resource.actions.delete(id)),
    };
  };

  const displayColumns = resource.columns.map((x) => x.dataIndex);

  return compose(
    dialog ? dialog : withProps({}),
    withProps({ resource }),
    connect(mapStateToProps, mapDispatchToProps),
    withState('filters', 'setFilters', {}),
    withState('displayColumns', 'setDisplayColumns', displayColumns),
    withHandlers({
      fetchAll:
        ({ getAll, filters }) =>
        () => {
          getAll(filters);
        },
      fetchDetail: () => (id) => {
        return api.detail(id);
      },
      fetchCreateOrUpdate:
        ({ updateOne, createOne }) =>
        (id, values) => {
          if (id) {
            return updateOne(id)(values);
          } else {
            return createOne(values);
          }
        },
      goToIndex: () => () => {
        history.push(resource.routes.index());
      },
    }),
    withHandlers({
      onCreate:
        ({ createOne, openDialog, fetchAll }) =>
        () => {
          if (isFunction(openDialog)) {
            openDialog({ resource, handleSubmit: createOne, fetchAll });
          } else {
            history.push(resource.routes.create());
          }
        },
      onUpdate:
        ({ openDialog, updateOne, fetchAll }) =>
        (id) => {
          if (isFunction(openDialog)) {
            api.detail(id).then(({ data }) => {
              openDialog({
                resource,
                values: data,
                handleSubmit: updateOne(id),
                fetchAll,
              });
            });
          } else {
            history.push(resource.routes.edit(id));
          }
        },
      onDelete:
        ({ deleteOne, fetchAll }) =>
        (id) => {
          Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: 'Хакикатдан хам учиришни хохлайсизми?',
            okText: 'Ха, учир!',
            cancelText: 'Бекор қилиш',
            okType: 'danger',
            onOk() {
              deleteOne(id).then(() => {
                fetchAll();
              });
            },
            onCancel() {},
          });
        },
      handleChangePagination:
        ({ setFilters, fetchAll }) =>
        (page, limit) => {
          setFilters(
            (prev) => ({ ...prev, page, limit }),
            () => {
              fetchAll();
            }
          );
        },
      handleChangeOrdering:
        ({ setFilters, fetchAll }) =>
        (column, direction = 'ASC') => {
          setFilters(
            (prev) => {
              const filters = { ...prev };

              if (!column) {
                delete filters['ordering'];
                return filters;
              }

              return {
                ...filters,
                ordering: (direction === 'DESC' ? '-' : '') + column,
              };
            },
            () => {
              fetchAll();
            }
          );
        },
      handleChangeSearch:
        ({ setFilters, fetchAll }) =>
        (search) => {
          setFilters(
            (prev) => ({ ...prev, page: 1, search }),
            () => {
              fetchAll();
            }
          );
        },
      handleChangeDistrict:
        ({ setFilters, fetchAll }) =>
        (district) => {
          setFilters(
            (prev) => ({ ...prev, page: 1, district }),
            () => {
              fetchAll();
            }
          );
        },
      handleChangeDisplayColumns:
        ({ setDisplayColumns }) =>
        (display) => {
          setDisplayColumns(display || []);
        },
      handleResetDisplayColumns:
        ({ setDisplayColumns }) =>
        () => {
          setDisplayColumns(displayColumns);
        },
    }),
    lifecycle({
      componentDidMount() {
        this.props.getAll();
      },
    }),

    ...fns
  );
};
