export function arrayFromLength(number) {
  return Array.from(new Array(number).keys()).map(k => k + 1);
}

export function objectToOptions(obj, excludeIds = []) {
  return Object.keys(obj)
    .filter(x => {
      if (excludeIds.length >= 1) {
        return !excludeIds.includes(x);
      }

      return true;
    })
    .map(id => {
      return { id, name: obj[id] };
    });
}
