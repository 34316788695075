import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { get } from 'lodash';

export const FieldText = ({
  name,
  prefix,
  rules,
  placeholder,
  errors,
  InputProps,
  ...FormItemProps
}) => {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const errorMessage = get(errors, ['message', name], '');
    setErrorMessage(errorMessage);
  }, [errors, name]);

  const itemProps = () => {
    const inputProps = {
      rules,
      name,
      colon: false,
      ...FormItemProps,
    };
    if (errorMessage) {
      return {
        ...inputProps,
        validateStatus: 'error',
        help: errorMessage,
      };
    }
    return inputProps;
  };

  const handleChange = () => {
    setErrorMessage('');
  };

  return (
    <Form.Item {...itemProps()}>
      <Input
        {...{ prefix, placeholder, ...InputProps }}
        onChange={handleChange}
      />
    </Form.Item>
  );
};
