import React from 'react';
import { Button, Pagination, Row, Col, Space, Empty } from 'antd';
import { map } from 'lodash';
import { TableViewHeader } from './TableViewHeader';
import { TableViewRow, TableViewRowLoading } from './TableViewRow';
import { ResourceViewSearch } from '../ResourceView/ResourceViewSearch';
import { arrayFromLength, objectToOptions } from '../../util/DataUtils';
import { FieldRegion, FieldSelect } from '../fields';
import { Districts } from '../../constants/AppConstants';
import { useCurrentUser } from '../../hooks/useCurrentUser';

export const TableView = ({
  resource,
  onCreate,
  all: { isLoading, hits, pagination },
  onUpdate,
  onDelete,
  displayColumns,
  handleChangePagination,
  handleChangeOrdering,
  handleChangeSearch,
  handleChangeDistrict,
  handleChangeDisplayColumns,
  handleResetDisplayColumns,
}) => {
  const { user } = useCurrentUser();
  const loadingRows = () => {
    return arrayFromLength(10).map((idx) => (
      <TableViewRowLoading
        key={idx}
        {...{ columns: resource.columns, displayColumns }}
      />
    ));
  };

  return (
    <>
      <Row className="gx-mb-3">
        <Col span={24} className="gx-flex-row">
          {!user.isModerator() && (
            <Button type="primary" onClick={onCreate}>
              Янги қушиш
            </Button>
          )}
          <div className="gx-ml-auto">
            <Space align="start" size="large">
              {resource.storeName === 'cemetery' && (
                <div style={{ width: 200 }}>
                  <FieldSelect
                    name="district"
                    placeholder="Туман буйича қидириш"
                    options={objectToOptions(Districts)}
                    onChange={handleChangeDistrict}
                  />
                </div>
              )}

              <ResourceViewSearch {...{ handleChangeSearch }} />
            </Space>
          </div>
        </Col>
      </Row>

      {pagination && pagination.total === 0 ? (
        <Empty
          className="gx-p-5"
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 150,
          }}
          description={<span>No data</span>}
        />
      ) : (
        <>
          <div className="ant-table">
            <div
              className="ant-table-container"
              style={{
                overflow: 'auto',
                backgroundColor: '#fff',
              }}>
              <div className="ant-table-content">
                <table style={{ tableLayout: 'auto' }}>
                  <TableViewHeader
                    {...{ columns: resource.columns, displayColumns }}
                  />
                  <tbody className="ant-table-tbody">
                    {isLoading
                      ? loadingRows()
                      : map(hits, (hit) => (
                          <TableViewRow
                            {...{
                              key: hit.id,
                              columns: resource.columns,
                              displayColumns,
                              hit,
                              onUpdate,
                              onDelete,
                              user,
                            }}
                          />
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {!isLoading && (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              showSizeChanger
              pageSize={pagination.perPage || 10}
              onChange={handleChangePagination}
              defaultCurrent={1}
              current={pagination.currentPage}
              total={pagination.total}
            />
          )}
        </>
      )}
    </>
  );
};
