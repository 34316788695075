import React, { useState } from 'react';
import { usePageTitle } from '../hooks/usePageTitle';
import Widget from '../components/Widget';
import { FieldSelectCemetery, FieldText } from '../components/fields';
import { Button, Form, Upload, Col, Row, message, Modal, Alert } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { BuriedApi } from '../http/requests/BuriedApi';

const api = new BuriedApi();

export const Import = () => {
  usePageTitle('Import');
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [form] = Form.useForm();
  const [formDelete] = Form.useForm();

  const handleSubmit = (values) => {
    if (!file || !values.cemetery) {
      message.error('Файл ва Қабристон танланг');
      return;
    }

    setUploading(true);

    const formData = new FormData();
    formData.append('file', file);

    const dateFormat = values.dateFormat || 'default';

    api
      .import(values.cemetery, dateFormat, formData)
      .then((res) => {
        message.success('Муваффақиятли юкланди');
        form.resetFields();
      })
      .catch((err) => {
        console.log('err', err);
        message.error('Хатолик юз берди');
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const handleDelete = (values) => {
    if (!values || !values.cemetery) {
      message.error('Қабристон танланг');
      return;
    }

    Modal.confirm({
      title: 'Тасдиқланг',
      content:
        'Танланган қабристон барча импорт маълумотлари ўчирилади. Шуни истайсизми?',
      okText: 'Тасдиқлаш',
      cancelText: 'Бекор қилиш',
      onOk() {
        setDeleting(true);
        api
          .deleteImport(values.cemetery)
          .then((res) => {
            message.success(
              `Муваффақиятли ўчирилди, ўчирилган маълумотлар сони ${res.data.count}`
            );
            formDelete.resetFields();
          })
          .catch((err) => {
            console.log('err', err);
            message.error('Хатолик юз берди');
          })
          .finally(() => {
            setDeleting(false);
          });
      },
    });
  };

  const props = {
    onRemove: (file) => {
      setFile(null);
    },
    beforeUpload: (file) => {
      setFile(file);
      return false;
    },
  };

  return (
    <>
      <Widget>
        <Alert
          message="Танланган қабристон учун маълумтоларни импорт қилиш"
          type="info"
        />

        <Form
          form={form}
          initialValues={{}}
          onFinish={handleSubmit}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          layout="horizontal">
          <FieldSelectCemetery name="cemetery" label="Қабристон" />
          <FieldText name="dateFormat" label="Сана формати" />
          <Alert
            message={
              <>
                {'Сана формати ўзгартириш, масалан: DD.MM.YYYY, DD/MM/YYYY'}
                <br />
                {'DD - кун, MM - ой, YYYY - йил.'}
              </>
            }
            type="info"
          />

          <Row>
            <Col span={8}></Col>
            <Col span={16}>
              <Upload {...props} maxCount={1}>
                <Button icon={<UploadOutlined />}>Файл танлаш</Button>
              </Upload>
            </Col>
          </Row>

          <Row style={{ marginTop: 30 }}>
            <Col span={8}></Col>
            <Col span={16}>
              <Button type="primary" htmlType="submit" loading={uploading}>
                Юклаш
              </Button>
            </Col>
          </Row>
        </Form>
      </Widget>

      <Widget>
        <Alert
          message="Танланган қабристон учун импорт қилинган барча маълумотларни ўчириш"
          type="info"
        />
        <Form
          form={formDelete}
          initialValues={{}}
          onFinish={handleDelete}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          layout="horizontal">
          <FieldSelectCemetery name="cemetery" label="Қабристон" />

          <Row style={{ marginTop: 30 }}>
            <Col span={8}></Col>
            <Col span={16}>
              <Button
                type="primary"
                danger={true}
                htmlType="submit"
                loading={deleting}>
                Ўчириш
              </Button>
            </Col>
          </Row>
        </Form>
      </Widget>
    </>
  );
};
