import React from 'react';
import { buildResource } from '../../helpers/buildResource';
import {
  fetchAllUser,
  fetchCreateUser,
  fetchDeleteUser,
  fetchUpdateUser,
} from './UserSlice';
import { get } from 'lodash';
import { Roles } from '../../constants/AppConstants';

export default buildResource('User', {
  actions: {
    all: fetchAllUser,
    create: fetchCreateUser,
    update: fetchUpdateUser,
    delete: fetchDeleteUser,
  },
  columns: [
    {
      title: 'Исм',
      dataIndex: 'firstName',
      render(value) {
        return (
          <>
            <b>{get(value, 'displayName')}</b>
            <br />
            <i>{get(value, 'username')}</i>
          </>
        );
      },
    },
    {
      title: 'Лавозим',
      dataIndex: 'role',
      render(value) {
        return get(Roles, value.role);
      },
    },
    {
      title: 'Иш жойи',
      dataIndex: 'cemetery',
      render(value) {
        return get(value, 'cemetery.name');
      },
    },
    {
      title: 'Ким Кушди/Узгартирди',
      dataIndex: 'created',
      className: 'gx-text-center',
      visible: false,
      render(value) {
        return (
          <div className="gx-text-center">
            {get(value, 'createdBy.displayName', '—')}
            <br />
            {get(value, 'updatedBy.displayName', '—')}
          </div>
        );
      },
    },
  ],
});
