import { defaultsDeep, snakeCase } from 'lodash';
import { pluralize } from 'inflection';

export const buildResource = (title, overrides = {}) => {
  const pluralTitle = pluralize(title);
  const name = snakeCase(title);
  const pluralName = snakeCase(pluralTitle);

  const defaults = {
    storeName: name,
    title: {
      singular: title,
      plural: pluralTitle,
    },
    name: {
      singular: name,
      plural: pluralName,
    },
    routes: {
      index: () => `/${pluralName}`,

      create: () => `/${pluralName}/create`,

      edit: (id) => `/${pluralName}/edit/${id}`,
    },
  };

  return defaultsDeep(overrides, defaults);
};
