import React, { useState, useCallback, useMemo } from 'react';
import _ from 'lodash';
import DialogContext from '../contexts/dialog';

function DialogProvider(props) {
  const { children } = props;
  const [dialogs, setComponents] = useState([]);

  const pushDialog = useCallback(
    (dialog) => {
      setComponents([...dialogs, dialog]);
    },
    [dialogs]
  );

  const popDialog = useCallback(
    (e, result) => {
      const dialog = dialogs.pop();

      if (dialog && _.isArray(dialog)) {
        const [, , resolve] = dialog;
        resolve(result);
      }

      setComponents([...dialogs]);
    },
    [dialogs]
  );

  const dialogContext = useMemo(() => {
    return { pushDialog, popDialog };
  }, [pushDialog, popDialog]);

  return (
    <DialogContext.Provider value={dialogContext}>
      {React.Children.only(children)}

      {dialogs.map(([component, componentProps], index) => {
        return React.createElement(component, {
          key: index,
          popDialog,
          onClose: popDialog,
          ...componentProps,
        });
      })}
    </DialogContext.Provider>
  );
}

export default DialogProvider;
