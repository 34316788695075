import MainApp from './components/layouts/MainApp';
import { SIGNIN } from './constants/RouteConstants';
import { SignIn, Users, Cemeteries, Burieds } from './pages';
import { Home } from './pages/Home';
import { StatsByCemetery } from './pages/StatsByCemetery';
import { Import } from './pages/Import';

export default [
  {
    component: SignIn,
    path: SIGNIN,
  },
  {
    component: MainApp,
    routes: [
      {
        path: '/',
        exact: true,
        component: Home,
      },
      {
        path: '/:district/stats',
        exact: true,
        component: StatsByCemetery,
      },
      {
        path: '/users',
        component: Users,
      },
      {
        path: '/cemeteries',
        component: Cemeteries,
      },
      {
        path: '/buried',
        component: Burieds,
      },
      {
        path: '/import',
        component: Import,
      },
    ],
  },
];
