import axios from 'axios';

const instanceAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instanceAxios.interceptors.request.use((config) => {
  if (!config.url) {
    return config;
  }

  const currentUrl = new URL(config.url, config.baseURL);
  Object.entries(config.pathParams || {}).forEach(([k, v]) => {
    currentUrl.pathname = currentUrl.pathname.replace(
      `:${k}`,
      encodeURIComponent(v)
    );
  });

  const authPart =
    currentUrl.username && currentUrl.password
      ? `${currentUrl.username}:${currentUrl.password}`
      : '';

  return {
    ...config,
    baseURL: `${currentUrl.protocol}//${authPart}${currentUrl.host}`,
    url: currentUrl.pathname,
  };
});

instanceAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      // store.dispatch(logout());
    } else {
      return Promise.reject(error);
    }
  }
);

export default instanceAxios;
