import React from 'react';
import { Card } from 'antd';
import Cemetery from '../features/cemetery/Cemetery';
import { usePageTitle } from '../hooks/usePageTitle';

export const Cemeteries = () => {
  usePageTitle('Қабристонлар');

  return (
    <Card className="gx-card">
      <Cemetery />
    </Card>
  );
};
