import { BaseApi } from './BaseApi';

const ALL_URL = '/api/v1/users';
const CREATE_URL = '/api/v1/users/';
const DETAIL_URL = '/api/v1/users/:id';
const UPDATE_URL = '/api/v1/users/:id';
const DELETE_URL = '/api/v1/users/:id';

export class UserApi extends BaseApi {
  all(params) {
    return this.httpClient.get(ALL_URL, { params });
  }

  create(data) {
    return this.httpClient.post(CREATE_URL, data);
  }

  detail(id) {
    return this.httpClient.get(DETAIL_URL, {
      pathParams: { id },
    });
  }

  update(id, data) {
    return this.httpClient.put(UPDATE_URL, data, {
      pathParams: { id },
    });
  }

  delete(id) {
    return this.httpClient.delete(DELETE_URL, {
      pathParams: { id },
    });
  }
}
