import React from 'react';
import _ from 'lodash';

export const TableViewHeader = ({ displayColumns, columns }) => {
  return (
    <thead className="ant-table-thead">
      <tr>
        {_(columns)
          .filter(column => displayColumns.indexOf(column.dataIndex) !== -1)
          .value()
          .map((column, idx) => (
            <th
              key={idx}
              className={'ant-table-cell ' + (column.className || '')}>
              {column.title}
            </th>
          ))}
        <th width={100} className="ant-table-cell" />
      </tr>
    </thead>
  );
};
