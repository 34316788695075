import composeResource from '../../helpers/composeResource';
import cemeteryResource from './CemeteryResource';
import { TableView } from '../../components/TableView/TableView';
import { CemeteryApi } from '../../http/requests/CemeteryApi';
import { withDialog } from '../../helpers/withDialog';
import { CemeteryEditDialog } from './CemeteryEditDialog';
const api = new CemeteryApi();

export default composeResource(
  api,
  cemeteryResource,
  withDialog(CemeteryEditDialog)
)(TableView);
