import React from 'react';
import { buildResource } from '../../helpers/buildResource';
import {
  fetchAllBuried,
  fetchCreateBuried,
  fetchDeleteBuried,
  fetchUpdateBuried,
} from './BuriedSlice';
import * as moment from 'moment';

export default buildResource('Buried', {
  actions: {
    all: fetchAllBuried,
    create: fetchCreateBuried,
    update: fetchUpdateBuried,
    delete: fetchDeleteBuried,
  },
  columns: [
    {
      title: '',
      dataIndex: 'info',
      render(obj) {
        const time = moment(obj.writtenAt);

        return (
          <div className="table-list-items">
            <h2 className="bold">{obj.fullName}</h2>
            <div>
              <b>Вафот этган сана:</b>{' '}
              {time.isValid() ? time.format('DD.MM.YYYY') : obj.writtenAt}
            </div>
            <div>
              <b>Ëши:</b> {obj.age || '-'}
            </div>
            <div>
              <b>Уй манзили:</b> {obj.homeAddress || '-'}
            </div>

            <div>
              <b>Қабристон:</b> {obj?.cemetery?.name || '-'}
            </div>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'info2',
      render(obj) {
        return (
          <div className="table-list-items">
            <div>
              <b>Қабр №:</b> {obj.qabr || '-'}
            </div>
            <div>
              <b>Хилхона:</b> {obj.xilhona || '-'}
            </div>
            <div>
              <b>Қайд этилган:</b> {obj.recorded || '-'}
            </div>
            <div>
              <b>ФХДË №:</b> {obj.fhdyo || '-'}
            </div>
            <div>
              <b>Улим сабаби:</b> {obj.causeOfDeath || '-'}
            </div>
            <div>
              <b>Дафн этувчи гўркўв:</b> {obj.gravedigger || '-'}
            </div>
          </div>
        );

        // return obj.fullName + ' ' + obj.age;
      },
    },
  ],
});
