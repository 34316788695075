import { BaseApi } from './BaseApi';
import { UserDTO } from '../../dto/UserDTO';

const AUTH_TOKEN_URL = '/api/v1/auth/login/';
const AUTH_USER_INFO_URL = '/api/v1/users/me';

export class AuthApi extends BaseApi {
  requestToken(credentials) {
    return this.httpClient.post(AUTH_TOKEN_URL, credentials);
  }

  userInfo() {
    return this.httpClient.get(AUTH_USER_INFO_URL).then(response => {
      return new UserDTO(response.data);
    });
  }
}
