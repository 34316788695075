import React, {useEffect, useState} from 'react';
import {Row, Col} from 'antd';
import {BuriedApi} from '../../http/requests/BuriedApi';
import Widget from '../../components/Widget';
import {map, get, keyBy, reduce} from 'lodash';
import moment from 'moment';
import {Districts} from '../../constants/AppConstants';
import {useParams} from 'react-router-dom';
import {months, translateMonth} from "../../util/utils";


const api = new BuriedApi();

export const BuriedStatsByCemetery = () => {
  const { district } = useParams();
  const [query] = useState({});
  const [stats, setStats] = useState({});

  useEffect(() => {
    api.statsByCemetery({ district }).then(({ data }) => {
      setStats(data);
    });
  }, [district, query]);

  const date = moment();
  const cemeteries = get(stats, 'cemeteries', []);
  const prevYearByCemetery = keyBy(get(stats, 'prevYear'), 'cemetery');
  const yearByCemetery = keyBy(get(stats, 'year'), 'cemetery');
  const districtName = get(Districts, district);

  const prevYearTotal = reduce(
    stats.prevYear,
    (acc, curr) => acc + parseInt(curr.count),
    0
  );

  const yearTotal = reduce(
    stats.year,
    (acc, curr) => acc + parseInt(curr.count),
    0
  );

  const totalMonth = (monthKey) => {
    return reduce(
        get(stats, ['months', monthKey], []),
      (acc, curr) => acc + parseInt(curr.count),
      0
    );
  };

  return (
    <Widget>
      <h1 className="mb-3 text-center">{districtName}</h1>
      <table className="gx-table gx-table-responsive ant-table table-bordered ant-table-bordered">
        <thead className="ant-table-thead">
        <tr>
          <th>Қабристон</th>
          {months.map((monthName) => (
            <th key={monthName}>{translateMonth(monthName)}</th>
          ))}
          <th>{date.format('YYYY')} йилда</th>
          <th>1969 - {date.clone().subtract(1, 'year').format('YY')}{' '}йилда</th>
        </tr>
        </thead>
        <tbody className="ant-table-body">
        {map(cemeteries, ({ id, name }) => (
          <tr key={id}>
            <td>{name}</td>
            {months.map((_, monthNumber) => (
              <td key={monthNumber}>
                {get(
                  stats.months[monthNumber].find((m) => m.cemetery === id),
                  'count',
                  0
                )}
              </td>
            ))}

            <td>{get(yearByCemetery, [id, 'count'], 0)}</td>
            <td>{get(prevYearByCemetery, [id, 'count'], 0)}</td>
          </tr>
        ))}
        <tr>
          <td><b>Жами</b></td>
          {months.map((_, monthNumber) => (
            <td key={monthNumber}>{totalMonth(monthNumber)}</td>
          ))}
          <td>{yearTotal}</td>
          <td>{prevYearTotal}</td>
        </tr>
        </tbody>
      </table>
    </Widget>
  );
};
