import React from 'react';
import { useDispatch } from 'react-redux';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { useEffect } from 'react';
import { fetchUserInfo } from '../store/Auth';
import CircularProgress from '../components/CircularProgress';

export const withAuthorization = BaseComponent => ({ ...props }) => {
  const dispatch = useDispatch();
  const { isSignedIn } = useCurrentUser();
  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  if (!isSignedIn) {
    return <CircularProgress />;
  }

  return <BaseComponent {...props} />;
};
