import { BaseApi } from './BaseApi';

const ALL_URL = '/api/v1/buried';
const CREATE_URL = '/api/v1/buried/';
const STATS_URL = '/api/v1/buried/stats';
const STATS_BY_CEMETERIES_URL = '/api/v1/buried/stats-by-cemeteries';
const DETAIL_URL = '/api/v1/buried/:id';
const UPDATE_URL = '/api/v1/buried/:id';
const DELETE_URL = '/api/v1/buried/:id';

export class BuriedApi extends BaseApi {
  all(params) {
    return this.httpClient.get(ALL_URL, { params });
  }

  stats(params) {
    return this.httpClient.get(STATS_URL, { params });
  }

  statsByCemetery(params) {
    return this.httpClient.get(STATS_BY_CEMETERIES_URL, { params });
  }

  create(data) {
    return this.httpClient.post(CREATE_URL, data);
  }

  detail(id) {
    return this.httpClient.get(DETAIL_URL, {
      pathParams: { id },
    });
  }

  update(id, data) {
    return this.httpClient.put(UPDATE_URL, data, {
      pathParams: { id },
    });
  }

  delete(id) {
    return this.httpClient.delete(DELETE_URL, {
      pathParams: { id },
    });
  }

  deleteImport(id) {
    return this.httpClient.delete(`/api/v1/buried/${id}/import`);
  }

  import(id, dateFormat, data) {
    return this.httpClient.post(`/api/v1/buried/import/${id}`, data, {
      params: {
        dateFormat: dateFormat || 'default',
      },
    });
  }
}
