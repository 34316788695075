import React from 'react';
import { Card } from 'antd';
import Buried from '../features/buried/Buried';
import { usePageTitle } from '../hooks/usePageTitle';

export const Burieds = () => {
  usePageTitle('Дафн этилганлар');

  return (
    <Card className="gx-card">
      <Buried />
    </Card>
  );
};
