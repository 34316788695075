import React, { useEffect, useState } from 'react';
import { BuriedApi } from '../../http/requests/BuriedApi';
import Widget from '../../components/Widget';
import { map, get, reduce } from 'lodash';
import moment from 'moment';
import { Districts } from '../../constants/AppConstants';
import { useHistory } from 'react-router-dom';
import { months, translateMonth } from '../../util/utils';

const api = new BuriedApi();

export const BuriedStats = () => {
  const history = useHistory();
  const [query] = useState({});
  const [stats, setStats] = useState({});

  useEffect(() => {
    api.stats(query).then(({ data }) => {
      setStats(data);
    });
  }, [query]);

  const handleClickRow = (district) => () => {
    history.push(`/${district}/stats`);
  };

  const date = moment();

  const prevYearTotal = reduce(
    Object.values(stats),
    (acc, curr) => acc + curr.prevYear,
    0
  );

  const yearTotal = reduce(
    Object.values(stats),
    (acc, curr) => acc + curr.year,
    0
  );

  const totalMonth = (monthKey) => {
    return reduce(
      Object.values(stats),
      (acc, curr) => acc + get(curr, ['months', monthKey], 0),
      0
    );
  };

  return (
    <Widget bodyStyle={{ padding: 0 }}>
      <table className="gx-table gx-table-responsive ant-table table-bordered ant-table-bordered">
        <thead className="ant-table-thead">
          <tr>
            <th>Туманлар</th>
            {months.map((monthName) => (
              <th key={monthName}>{translateMonth(monthName)}</th>
            ))}
            <th>{date.format('YYYY')} йилда</th>
            <th>69 - {date.clone().subtract(1, 'year').format('YY')} йилда</th>
          </tr>
        </thead>
        <tbody className="ant-table-body">
          {map(Districts, (district, key) => (
            <tr
              onClick={handleClickRow(key)}
              style={{
                cursor: 'pointer',
              }}
              key={key}
              className="stats-tr">
              <td style={{ width: 190 }}>{district}</td>
              {months.map((_, monthNumber) => (
                <td key={monthNumber}>
                  {get(stats, [key, 'months', monthNumber], 0)}
                </td>
              ))}
              <td>{get(stats, [key, 'year'], 0)}</td>
              <td>{get(stats, [key, 'prevYear'], 0)}</td>
            </tr>
          ))}
          <tr>
            <td>
              <b>Жами</b>
            </td>
            {months.map((_, monthNumber) => (
              <td key={monthNumber}>{totalMonth(monthNumber)}</td>
            ))}
            <td>{yearTotal}</td>
            <td>{prevYearTotal}</td>
          </tr>
        </tbody>
      </table>
    </Widget>
  );
};
