import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useCurrentUser = () => {
  const user = useSelector(({ auth }) => auth.user);
  const isSignedIn = !!user;

  return useMemo(
    () => ({
      isSignedIn,
      user,
    }),
    [isSignedIn, user]
  );
};
