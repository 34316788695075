import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';

export const ResourceViewSearch = ({ handleChangeSearch }) => {
  const [debouncedCallback] = useDebouncedCallback(value => {
    handleChangeSearch(value);
  }, 1000);

  return (
    <Input
      prefix={<SearchOutlined />}
      placeholder="Кидириш"
      onChange={e => debouncedCallback(e.target.value)}
    />
  );
};
