import React from 'react';
import { Avatar, Popover, Space } from 'antd';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { logout } from '../../store/Auth';
import { useDispatch } from 'react-redux';

const UserInfo = () => {
  const dispatch = useDispatch();
  const { user } = useCurrentUser();
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => dispatch(logout())}>Чиқиш</li>
    </ul>
  );

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click">
      <Space>
        <div style={{ fontSize: 13, color: '#fff' }}>
          <div>{user.displayName}</div>
          <div className="gx-text-right">{user.role}</div>
        </div>
        <Avatar className="gx-avatar gx-pointer" alt="" />
      </Space>
    </Popover>
  );
};

export default UserInfo;
