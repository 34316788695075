import React from 'react';
import { Form } from 'antd';
import { useHistory } from 'react-router-dom';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { FieldPassword, FieldText } from '../components/fields';
import { Heading, Button } from '../components/ui';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogin } from '../store/Auth';

export const SignIn = () => {
  const { isLoading, errors } = useSelector(({ auth }) => auth.fetchLogin);
  const dispatch = useDispatch();
  const history = useHistory();

  const onFinish = (values) => {
    dispatch(fetchLogin(values)).then((res) => {
      if (!res.error) history.push('/');
    });
  };

  return (
    <div
      className="gx-app-login-wrap"
      style={{ background: 'url("background.jpeg") cover' }}>
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-login-content">
            <Heading level={3} center>
              Тизимга кириш
            </Heading>
            <Form
              initialValues={{
                remember: true,
              }}
              name="basic"
              onFinish={onFinish}
              className="gx-signin-form gx-form-row0">
              <FieldText
                rules={[{ required: true, message: 'Илтимос, логин киритинг' }]}
                name="username"
                placeholder="Логин"
                prefix={<UserOutlined />}
                errors={errors}
              />
              <FieldPassword
                rules={[{ required: true, message: 'Илтимос, парол киритинг' }]}
                name="password"
                placeholder="Парол"
                prefix={<LockOutlined />}
                errors={errors}
              />

              <Button
                type="primary"
                htmlType="submit"
                block
                loading={isLoading}>
                Кириш
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
