import React, { useEffect, useState } from 'react';
import { fetchListCemetery } from '../../features/cemetery/CemeterySlice';
import { useDispatch, useSelector } from 'react-redux';
import { FieldSelect } from './FieldSelect';
import { get } from 'lodash';

export const FieldSelectCemetery = ({ ...props }) => {
  const dispatch = useDispatch();
  const cemeteryList = useSelector(state => state.cemetery.list);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    dispatch(fetchListCemetery());
  }, [dispatch]);

  useEffect(() => {
    const hits = get(cemeteryList, 'payload.hits', []);

    setOptions(
      hits.map(hit => ({
        id: hit.id,
        name: hit.name,
      }))
    );
  }, [cemeteryList]);

  return <FieldSelect options={options} {...props} />;
};
