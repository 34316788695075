import React, { useState } from 'react';
import { Modal, Form } from 'antd';
import { isEmpty } from 'lodash';
import {
  FieldPassword,
  FieldSelect,
  FieldSelectCemetery,
  FieldText,
} from '../../components/fields';
import { objectToOptions } from '../../util/DataUtils';
import { Roles } from '../../constants/AppConstants';
import { useCurrentUser } from '../../hooks/useCurrentUser';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

export const UserEditDialog = ({
  resource,
  values = {},
  popDialog,
  handleSubmit,
  fetchAll,
}) => {
  const { user } = useCurrentUser();
  const [errors, setErrors] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const isEdit = !isEmpty(values);

  const handleOk = () => {
    form.validateFields().then((values) => {
      setIsLoading(true);

      handleSubmit(values).then((res) => {
        if (isEmpty(res.error)) {
          fetchAll();
          popDialog();
        } else {
          setErrors(res.payload);
        }
        setIsLoading(false);
      });
    });
  };

  const processValue = (value) => {
    const res = { ...value };
    if (res.cemetery) {
      res.cemetery = res.cemetery.id;
    }
    return res;
  };

  return (
    <Modal
      title={isEdit ? `Ўзгартириш` : `Янги қушиш`}
      visible
      okText={isEdit ? 'Ўзгартириш' : 'Янги қушиш'}
      cancelText="Бекор қилиш"
      onOk={handleOk}
      okButtonProps={{
        loading: isLoading,
      }}
      onCancel={popDialog}>
      <Form
        form={form}
        initialValues={processValue(values)}
        name="basic"
        onFinish={handleSubmit}
        className="gx-form-row0">
        <FieldText {...layout} name="firstName" label="Исм" errors={errors} />
        <FieldText
          {...layout}
          name="lastName"
          label="Фамилия"
          errors={errors}
        />
        <FieldSelect
          {...layout}
          name="role"
          label="Лавозим"
          options={objectToOptions(Roles, user.isAdmin() ? [] : ['ADMIN'])}
          errors={errors}
        />
        {user.isAdmin() && (
          <FieldSelectCemetery {...layout} name="cemetery" label="Иш жойи" />
        )}
        <FieldText
          {...layout}
          name="username"
          label="Логин"
          errors={errors}
          InputProps={{
            disabled: isEdit,
          }}
        />
        <FieldPassword
          {...layout}
          name="password"
          label="Парол"
          errors={errors}
        />
      </Form>
    </Modal>
  );
};
