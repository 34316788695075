import React, { useState } from 'react';
import { Modal, Form } from 'antd';
import { isEmpty } from 'lodash';
import { FieldSelect, FieldText } from '../../components/fields';
import { objectToOptions } from '../../util/DataUtils';
import { Districts } from '../../constants/AppConstants';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

export const CemeteryEditDialog = ({
  resource,
  values = {},
  popDialog,
  handleSubmit,
  fetchAll,
}) => {
  const [errors, setErrors] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const isEdit = !isEmpty(values);

  const handleOk = () => {
    form.validateFields().then((values) => {
      setIsLoading(true);

      handleSubmit(values).then((res) => {
        if (isEmpty(res.error)) {
          fetchAll();
          popDialog();
        } else {
          setErrors(res.payload);
        }
        setIsLoading(false);
      });
    });
  };

  return (
    <Modal
      title={isEdit ? `Ўзгартириш` : `Янги қушиш`}
      visible
      okText={isEdit ? 'Ўзгартириш' : 'Янги қушиш'}
      cancelText="Бекор қилиш"
      onOk={handleOk}
      okButtonProps={{
        loading: isLoading,
      }}
      onCancel={popDialog}>
      <Form
        form={form}
        initialValues={values}
        name="basic"
        onFinish={handleSubmit}
        className="gx-form-row0">
        <FieldSelect
          {...layout}
          name="district"
          label="Туман"
          options={objectToOptions(Districts)}
          errors={errors}
        />
        <FieldText {...layout} name="name" label="Номи" errors={errors} />
        <FieldText {...layout} name="address" label="Манзил" errors={errors} />
        <FieldText {...layout} name="nation" label="Миллат" errors={errors} />
        <FieldText {...layout} name="area" label="Майдони" errors={errors} />
      </Form>
    </Modal>
  );
};
