import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { updatePageTitle } from '../store/Settings';

export const usePageTitle = title => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updatePageTitle(title));
  }, [dispatch, title]);
};
