import { createSlice } from '@reduxjs/toolkit';
import { createFetchAsync } from '../../util/FetchSliceUtils';
import { BuriedApi } from '../../http/requests/BuriedApi';
const api = new BuriedApi();

const fetchListBuriedAsync = createFetchAsync(
  'Buried',
  'list',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.list();
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
const fetchAllBuriedAsync = createFetchAsync(
  'Buried',
  'all',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.all({
        ...data,
        embed: 'cemetery',
        order: 'id',
        limit: 50,
      });
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
const fetchCreateBuriedAsync = createFetchAsync(
  'Buried',
  'create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.create(data);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

const fetchUpdateBuriedAsync = createFetchAsync(
  'Buried',
  'update',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await api.update(id, data);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

const fetchDeleteBuriedAsync = createFetchAsync(
  'Buried',
  'delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.delete(id);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const fetchAllBuried = fetchAllBuriedAsync.asyncThunk;
export const fetchCreateBuried = fetchCreateBuriedAsync.asyncThunk;
export const fetchUpdateBuried = fetchUpdateBuriedAsync.asyncThunk;
export const fetchDeleteBuried = fetchDeleteBuriedAsync.asyncThunk;

const buriedSlice = createSlice({
  name: 'Buried',
  initialState: {
    all: fetchAllBuriedAsync.initialState,
    create: fetchCreateBuriedAsync.initialState,
    update: fetchUpdateBuriedAsync.initialState,
    delete: fetchDeleteBuriedAsync.initialState,
    list: fetchListBuriedAsync.initialState,
  },
  extraReducers: {
    ...fetchAllBuriedAsync.extraReducers,
    ...fetchCreateBuriedAsync.extraReducers,
    ...fetchUpdateBuriedAsync.extraReducers,
    ...fetchDeleteBuriedAsync.extraReducers,
    ...fetchListBuriedAsync.extraReducers,
  },
});

export default buriedSlice.reducer;
