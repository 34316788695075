import composeResource from '../../helpers/composeResource';
import userResource from './UserResource';
import { TableView } from '../../components/TableView/TableView';
import { UserApi } from '../../http/requests/UserApi';
import { withDialog } from '../../helpers/withDialog';
import { UserEditDialog } from './UserEditDialog';
const api = new UserApi();

export default composeResource(
  api,
  userResource,
  withDialog(UserEditDialog)
)(TableView);
