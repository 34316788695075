import { createSlice } from '@reduxjs/toolkit';
import { createFetchAsync } from '../../util/FetchSliceUtils';
import { CemeteryApi } from '../../http/requests/CemeteryApi';
const api = new CemeteryApi();

const fetchListCemeteryAsync = createFetchAsync(
  'Cemetery',
  'list',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.all({ limit: 1000 });
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
const fetchAllCemeteryAsync = createFetchAsync(
  'Cemetery',
  'all',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.all(data);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
const fetchCreateCemeteryAsync = createFetchAsync(
  'Cemetery',
  'create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.create(data);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

const fetchUpdateCemeteryAsync = createFetchAsync(
  'Cemetery',
  'update',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await api.update(id, data);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

const fetchDeleteCemeteryAsync = createFetchAsync(
  'Cemetery',
  'delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.delete(id);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const fetchAllCemetery = fetchAllCemeteryAsync.asyncThunk;
export const fetchCreateCemetery = fetchCreateCemeteryAsync.asyncThunk;
export const fetchUpdateCemetery = fetchUpdateCemeteryAsync.asyncThunk;
export const fetchDeleteCemetery = fetchDeleteCemeteryAsync.asyncThunk;
export const fetchListCemetery = fetchListCemeteryAsync.asyncThunk;

const cemeterySlice = createSlice({
  name: 'Cemetery',
  initialState: {
    all: fetchAllCemeteryAsync.initialState,
    create: fetchCreateCemeteryAsync.initialState,
    update: fetchUpdateCemeteryAsync.initialState,
    delete: fetchDeleteCemeteryAsync.initialState,
    list: fetchListCemeteryAsync.initialState,
  },
  extraReducers: {
    ...fetchAllCemeteryAsync.extraReducers,
    ...fetchCreateCemeteryAsync.extraReducers,
    ...fetchUpdateCemeteryAsync.extraReducers,
    ...fetchDeleteCemeteryAsync.extraReducers,
    ...fetchListCemeteryAsync.extraReducers,
  },
});

export default cemeterySlice.reducer;
