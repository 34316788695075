import moment from "moment/moment";


const now = moment();

export const months = [
    ...[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => now.clone().subtract(i, 'month').format('MMM YY[й.]')),
];

export const monthsUz = {
    'Sep': 'Сен',
    'Oct': 'Окт',
    'Nov': 'Ноя',
    'Dec': 'Дек',
    'Jan': 'Янв',
    'Feb': 'Фев',
    'Mar': 'Мар',
    'Apr': 'Апр',
    'May': 'Май',
    'Jun': 'Июн',
    'Jul': 'Июл',
    'Aug': 'Авг',
}

export const translateMonth = (monthString) => {
    return Object.keys(monthsUz).reduce((acc, curr) => {
        return acc.replace(curr, monthsUz[curr]);
    }, monthString);
}
