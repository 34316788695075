import { BaseApi } from './BaseApi';

const LIST_URL = '/api/v1/cemetery/list';
const ALL_URL = '/api/v1/cemetery';
const CREATE_URL = '/api/v1/cemetery/';
const DETAIL_URL = '/api/v1/cemetery/:id';
const UPDATE_URL = '/api/v1/cemetery/:id';
const DELETE_URL = '/api/v1/cemetery/:id';

export class CemeteryApi extends BaseApi {
  list(params) {
    return this.httpClient.get(LIST_URL, { params });
  }

  all(params) {
    return this.httpClient.get(ALL_URL, { params });
  }

  create(data) {
    return this.httpClient.post(CREATE_URL, data);
  }

  detail(id) {
    return this.httpClient.get(DETAIL_URL, {
      pathParams: { id },
    });
  }

  update(id, data) {
    return this.httpClient.put(UPDATE_URL, data, {
      pathParams: { id },
    });
  }

  delete(id) {
    return this.httpClient.delete(DELETE_URL, {
      pathParams: { id },
    });
  }
}
