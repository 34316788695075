import React, { useEffect, useState } from 'react';
import { Form, DatePicker } from 'antd';
import { get } from 'lodash';

export const FieldDatePicker = ({
  name,
  prefix,
  rules,
  placeholder,
  errors,
  InputProps,
  ...FormItemProps
}) => {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const errorMessage = get(errors, ['message', name], '');
    setErrorMessage(errorMessage);
  }, [errors, name]);

  const itemProps = () => {
    let inputProps = {
      rules,
      name,
      colon: false,
      ...FormItemProps,
    };
    if (errorMessage) {
      return {
        ...inputProps,
        validateStatus: 'error',
        help: errorMessage,
      };
    }
    return inputProps;
  };

  const handleChange = (date, dateString) => {
    setErrorMessage('');
  };

  return (
    <Form.Item {...itemProps()}>
      <DatePicker
        {...{
          prefix,
          placeholder: 'Сана танланг',
          style: { width: 200 },
          ...InputProps,
        }}
        onChange={handleChange}
      />
    </Form.Item>
  );
};
