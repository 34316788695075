import React from 'react';
import { Layout, Popover } from 'antd';
import { connect } from 'react-redux';
import UserInfo from 'components/UserInfo';
import HorizontalNav from '../HorizontalNav';
import { Link } from 'react-router-dom';
import {
  switchLanguage,
  toggleCollapsedSideNav,
} from '../../../../store/Settings';
import { useCurrentUser } from '../../../../hooks/useCurrentUser';

const { Header } = Layout;

const InsideHeader = () => {
  const { user } = useCurrentUser();

  const userMenuOptions = () => {
    const menu = [];
    if (user.isAdmin()) {
      menu.push(
        <li key="cemeteries">
          <Link to="/cemeteries">Қабристонлар</Link>
        </li>
      );
    }

    if (user.isAdmin() || user.isDirector()) {
      menu.push(
        <li key="users">
          <Link to="/users">Ходимлар</Link>
        </li>
      );
    }

    menu.push(
      <li key="buried">
        <Link to="/buried">Дафн этилганлар</Link>
      </li>
    );

    return <ul className="gx-user-popover">{menu}</ul>;
  };

  return (
    <div className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal">
      <Header className="gx-header-horizontal-main">
        <div className="gx-container">
          <div className="gx-header-horizontal-main-flex">
            <Popover
              overlayClassName="gx-popover-horizantal"
              placement="bottomRight"
              content={userMenuOptions()}
              trigger="click">
              <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e">
                <i className="gx-icon-btn icon icon-menu" />
              </div>
            </Popover>
            <Link
              to="/"
              className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo">
              {user.cemetery ? (
                <span style={{ fontSize: 20, color: '#fff' }}>
                  {user.cemetery.name}
                </span>
              ) : (
                <span style={{ fontSize: 25, color: '#fff' }}>Qabriston</span>
              )}
            </Link>

            <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block">
              <HorizontalNav />
            </div>
            <ul className="gx-header-notifications gx-ml-auto">
              <li className="gx-user-nav">
                <UserInfo />
              </li>
            </ul>
          </div>
        </div>
      </Header>
    </div>
  );
};

const mapStateToProps = ({ settings }) => {
  const { locale, navCollapsed } = settings;
  return { locale, navCollapsed };
};
export default connect(mapStateToProps, {
  toggleCollapsedSideNav,
  switchLanguage,
})(InsideHeader);
