import React from 'react';
import { Layout } from 'antd';
import { renderRoutes } from 'react-router-config';
import { compose } from 'recompose';
import { withAuthorization } from '../../helpers/withAuthorization';
import { useSelector } from 'react-redux';
import InsideHeader from './Topbar/InsideHeader';

const { Content } = Layout;

const MainApp = ({ route }) => {
  const pageTitle = useSelector(state => state.settings.pageTitle);
  return (
    <Layout className="gx-app-layout">
      <Layout>
        <InsideHeader />
        <Content className={`gx-layout-content gx-container-wrap`}>
          <div className="gx-main-content-wrapper">
            <h1 className="gx-mb-3">{pageTitle}</h1>
            {renderRoutes(route.routes)}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default compose(withAuthorization)(MainApp);
