import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import SettingsReducer from './Settings';
import AuthReducer from './Auth';
import UserReducer from '../features/user/UserSlice';
import CemeteryReducer from '../features/cemetery/CemeterySlice';
import BuriedReducer from '../features/buried/BuriedSlice';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    settings: SettingsReducer,
    auth: AuthReducer,

    user: UserReducer,
    cemetery: CemeteryReducer,
    buried: BuriedReducer,
  });

export default createRootReducer;
