import composeResource from '../../helpers/composeResource';
import buriedResource from './BuriedResource';
import { TableView } from '../../components/TableView/TableView';
import { BuriedApi } from '../../http/requests/BuriedApi';
import { withDialog } from '../../helpers/withDialog';
import { BuriedEditDialog } from './BuriedEditDialog';
const api = new BuriedApi();

export default composeResource(
  api,
  buriedResource,
  withDialog(BuriedEditDialog)
)(TableView);
