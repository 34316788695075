import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button as AntdButton } from 'antd';

export const Button = ({ children, to, linkMode, ...props }) => {
  const history = useHistory();

  return to ? (
    <AntdButton
      {...props}
      onClick={() => {
        if (linkMode === 'replace') {
          history.replace(to);
        } else {
          history.push(to);
        }
      }}>
      {children}
    </AntdButton>
  ) : (
    <AntdButton {...props}>{children}</AntdButton>
  );
};
