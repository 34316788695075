import React from 'react';
import { Card } from 'antd';
import User from '../features/user/User';
import { usePageTitle } from '../hooks/usePageTitle';

export const Users = () => {
  usePageTitle('Ходимлар');

  return (
    <Card className="gx-card">
      <User />
    </Card>
  );
};
