import { buildResource } from '../../helpers/buildResource';
import {
  fetchAllCemetery,
  fetchCreateCemetery,
  fetchDeleteCemetery,
  fetchUpdateCemetery,
} from './CemeterySlice';
import { get } from 'lodash';
import { Districts } from '../../constants/AppConstants';

export default buildResource('Cemetery', {
  actions: {
    all: fetchAllCemetery,
    create: fetchCreateCemetery,
    update: fetchUpdateCemetery,
    delete: fetchDeleteCemetery,
  },
  columns: [
    {
      title: 'Номи',
      dataIndex: 'name',
    },
    {
      title: 'Туман',
      dataIndex: 'district',
      render(value) {
        return get(Districts, value.district);
      },
    },
    {
      title: 'Майдони',
      dataIndex: 'area',
    },
    {
      title: 'Миллат',
      dataIndex: 'nation',
    },
    {
      title: 'Манзил',
      dataIndex: 'address',
    },
  ],
});
