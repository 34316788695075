import React from 'react';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useCurrentUser } from '../../../hooks/useCurrentUser';

const HorizontalNav = () => {
  const { user } = useCurrentUser();
  const pathname = useSelector(({ settings }) => settings.pathname);

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  return (
    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      mode="horizontal">
      {user.isAdmin() && (
        <Menu.Item key="cemeteries">
          <Link to="/cemeteries" className="gx-text-white">
            <i className="icon icon-widgets" />
            Қабристонлар
          </Link>
        </Menu.Item>
      )}
      {(user.isAdmin() || user.isDirector()) && (
        <Menu.Item key="users">
          <Link to="/users" className="gx-text-white">
            <i className="icon icon-user" />
            Ходимлар
          </Link>
        </Menu.Item>
      )}

      <Menu.Item key="buried">
        <Link to="/buried" className="gx-text-white">
          <i className="icon icon-widgets" />
          Дафн этилганлар
        </Link>
      </Menu.Item>

      {user.isAdmin() && !user.isModerator() && (
        <Menu.Item key="import">
          <Link to="/import" className="gx-text-white">
            <i className="icon icon-widgets" />
            Импорт
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default HorizontalNav;
